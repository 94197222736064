import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../../translations";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
    GoBackButtonIcon,
    ShareButtonIcon,
} from "../../../../assets/icons/icons";
import { useViewportSize } from "../../../../hooks/useViewportSize";

const useStyles = createUseStyles((theme) => ({
    avatarWrapper: {
        position: "relative",
        width: "100%",
        height: 336,
        "@media (min-width: 576px)": {
            height: 664,
        },
    },
    avatar: {
        height: "100%",
        width: "100%",
        objectFit: "contain",
    },
    goBackButtonIcon: {
        position: "absolute",
        top: 31,
        left: "2%",
        cursor: "pointer",
    },
    shareButtonIcon: {
        position: "absolute",
        top: 31,
        right: "2%",
        cursor: "pointer",
    },
}));

const AvatarSection = ({ rookie, onShare }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const classes = useStyles({});

    const history = useHistory();

    const { vw } = useViewportSize();
    const isMobile = vw * 100 <= 448;

    return (
        <div className={classes.avatarWrapper}>
            <img
                src={rookie?.avatar?.url}
                className={classes.avatar}
                alt="Avatar"
            />
            <GoBackButtonIcon
                onClick={() => history.goBack()}
                width={isMobile ? 32 : 54}
                height={isMobile ? 32 : 54}
                className={classes.goBackButtonIcon}
            />
            <ShareButtonIcon
                onClick={onShare}
                width={isMobile ? 32 : 54}
                height={isMobile ? 32 : 54}
                className={classes.shareButtonIcon}
            />
        </div>
    );
};

export default AvatarSection;
